import React, { Component } from "react"
import Contact from "../components/Contact"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default class contactUs extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Contact Us" />
        <div className="container">
          <div className="full-height d-flex flex-column">
            <h1 className="text-center page-heading m-0 pt-3 pb-3 px-5">
              You are one step away from taking your organization to Industry
              4.0
            </h1>
            <div className="row m-0 align-items-center flex-fill">
              <div className="col-md-6 p-3 px-md-0 align-self-stretch">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.3160721592885!2d77.63960351381407!3d12.887386190911148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15f0ed4e290d%3A0xc8937162b525c36!2sMachstatz!5e0!3m2!1sen!2sin!4v1583400712102!5m2!1sen!2sin"
                  className="location-map"
                  allowFullScreen={false}
                ></iframe>
              </div>
              <div className="col-md-6 px-2 pl-md-5 pr-md-0">
                <h2 className="contact-message m-0 pb-3">
                  Let's talk about how we can achieve that together.
                </h2>
                <Contact />
              </div>
            </div>
          </div>
          <div className="address px-2 pt-5 row">
            <div className="col-12 col-md-6">
              <h2 className="section-heading m-0">Careers</h2>
              <p className="py-2">
                Explore opportunities to take your career to the next level. Discover the impact 
                you could make with a career at Machstatz. Send in your resumes at&nbsp;
                <a target="_blank" className="text-info" href = "mailto: hr@machstatz.com">hr@machstatz.com</a> or click&nbsp;
                <a target="_blank" className="text-info" href = "https://machstatz.zohorecruit.com/jobs/Careers">here</a>
              </p>
            </div>
            <div className="col-12 col-md-6">
              <h2 className="section-heading m-0">Enquiry/Partnership Enquiries ?</h2>
              <br/>
              <p className="py-2">
                For enquiry related query. Write us at&nbsp;
                <a target="_blank" className="text-info" href = "mailto: info@machstatz.com">info@machstatz.com</a>
              
              </p>
              <p className="py-2" style={{textAlign:'start',width:'105%'}}>
                For partnership related query. Write us at&nbsp;
                <a target="_blank" className="text-info" href = "mailto: info@machstatz.com">sales@machstatz.com</a>
              <br />
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
